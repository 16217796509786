// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-nyheder-js": () => import("./../../../src/pages/nyheder.js" /* webpackChunkName: "component---src-pages-nyheder-js" */),
  "component---src-templates-template-artist-js": () => import("./../../../src/templates/template-artist.js" /* webpackChunkName: "component---src-templates-template-artist-js" */),
  "component---src-templates-template-page-js": () => import("./../../../src/templates/template-page.js" /* webpackChunkName: "component---src-templates-template-page-js" */),
  "component---src-templates-template-post-js": () => import("./../../../src/templates/template-post.js" /* webpackChunkName: "component---src-templates-template-post-js" */)
}

